<template>
    <div>
        <div class="container-fluid">
            <div class="container my-5 py-5">
                <div class="d-flex flex-column align-items-center">
                    <img class="col-8 col-lg-4 mb-5" :src="vector.url" alt="" />
                    <h1 class="col-12 col-lg-8 fw-bold text-center text-darkblue my-4">
                        ANNUAL STATUTORY AUDIT
                    </h1>
                    <div class="col-12 col-lg-6 text-center mb-5">
                        <h5 class="fw-light mb-4">
                            To us, an audit is more than just a statement or opinion, its advice on how to operate your business more smartly.
                        </h5>
                        <p>
                            Our Audit Division provides audit services to both local and international companies. We have a dedicated team of Thai CPA’s and our experience covers a wide range of industries.
                        </p>
                    </div>
                    <div class="col-12 col-lg-8">
                        <h5 class="mb-3">
                            Our audit and assurance professional provide the following key services :
                        </h5>
                        <ul>
                            <li v-for="(service, index) in services" :key="index" class="py-1">
                                {{ service }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
li {
    list-style: "—   ";
}
</style>
<script>
export default {
    data() {
        return {
            vector: {
                url: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fworking.svg?alt=media&token=378e7173-eaa0-46fe-93c2-00d324e9bc3d"
            },
            services: [
                "Statutory audits and review of financial statements required by regulatory bodies",
                "IFRS and US GAAP audits and reviews",
                "Audits and reviews of consolidation reporting packages",
                "Special purpose audits",
                "Agreed-Upon Procedures Regarding Financial Information including reports to the Board of Investment (BOI)",
                "Internal control review and advisory",
                "Internal audit support"
            ]
        }
    }
}
</script>